import styled from 'styled-components';

export const BaseButton = styled.button `
  /* min-width: 165px; */
  width: auto;
  height: 60px;
  letter-spacing: 0.5px;
  padding: 9px 35px 9px 35px;
  font-size: 1rem;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }

  h2{
    margin-top: 5px; 
  }
`

export const GoogleSignInButton  = styled(BaseButton)`
background-color: #4285f4;
    color: white;

    &:hover {
      background-color: #357ae8;
      border: none;
    }

`

export const InvertedButton = styled(BaseButton)`

    background-color: white;
    color: black;
    border: 1px solid black;
    

    &:hover {
      background-color: black;
      color: white;
      border: none;
    }
`




